import { render, staticRenderFns } from "./yqg-footer.vue?vue&type=template&id=41e0d5d7&scoped=true&"
import script from "./yqg-footer.js?vue&type=script&lang=js&"
export * from "./yqg-footer.js?vue&type=script&lang=js&"
import style0 from "./yqg-footer.scss?vue&type=style&index=0&id=41e0d5d7&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e0d5d7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41e0d5d7')) {
      api.createRecord('41e0d5d7', component.options)
    } else {
      api.reload('41e0d5d7', component.options)
    }
    module.hot.accept("./yqg-footer.vue?vue&type=template&id=41e0d5d7&scoped=true&", function () {
      api.rerender('41e0d5d7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/guanran-tech/index/component/yqg-footer/yqg-footer.vue"
export default component.exports